'use strict';

(function () {
  var self;
  class AddEditUserModalCtrl {
    constructor($scope, $uibModalInstance, Auth, Site, User, Util, Organization, options, usSpinnerService) {
      this.Util = Util;
      this.Site = Site;
      this.User = User;
      this.Organization = Organization;
      this.isAdmin = Auth.isAdmin;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.options = options;
      this.submitted = false;
      this.name = 'ui-multiselect';
      this.selected_id_match = '';
      this.currentUser = Auth.getCurrentUser();

      self = this;

      if (this.options.user) {
        this.user = angular.copy(this.options.user);
        if(!this.user.sites) {
          this.user.sites = [];
        }
        this.mode = 'UPDATE';
      } else {
        this.user = {
          name: null,
          email: null,
          organization: Auth.isAdmin()?null:this.currentUser.organization,
          sites: [],
          role: 'user'
        };
        this.mode = 'CREATE';
      }

      this.multiSelectDropdownSettings = {
        displayProp: 'name',
        idProperty: '_id'
      };

      console.log('Auth.isAdmin', Auth.isAdmin());
      if(Auth.isAdmin()) {
        this.organizations = [];
        this.Organization.get().$promise
          .then(response=> {
            if (response.data && response.data.length) {
              self.organizations = response.data.sort((a,b) => a.name.localeCompare(b.name));
              // self.user.organization = _.find(self.organizations, { _id: self.user.organization._id });
              this.organizationChanged();
            }
          });
      } else {
        this.organizationChanged();
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });
    }

    selectItem($item) {
      if (this.user.sites.indexOf($item._id) === -1) {
        this.user.sites.push($item._id);
      }
      this.selected_id_match = '';
    }

    unselectItem($item) {
      var index;
      index = this.user.sites.indexOf($item._id);
      if (index !== -1) {
        return this.user.sites.splice(index, 1);
      }
    }

    organizationChanged(orgChanged) {
      console.log(this.user.organization);
      if(!this.user.organization || !this.user.organization._id) {
        return;
      }
      if(orgChanged) {
        this.user.sites = [];
      }
      var userSites = angular.copy(this.user.sites);
      this.sites = [];
      this.Site.get({ id: 'for-org', controller: this.user.organization._id}).$promise
        .then(response=> {
          if (response.data && response.data.length) {
            if(!orgChanged) {
              this.user.sites = _.filter(response.data, site => userSites.indexOf(site._id) >= 0);
            }
            this.sites = _.filter(response.data, site => {
              site.ticked = userSites.indexOf(site._id) >= 0;
              return site;
            });
          }
        });
    }

    saveUser(form) {
      this.submitted = true;
      this.serverError = null;

      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        var tempOrganization = {};
        if(this.user.organization._id) {
          tempOrganization = angular.copy(this.user.organization);
          this.user.organization = this.user.organization._id;
        }
        var tempUserSites = [];
        if(this.user.sites && this.user.sites.length) {
          tempUserSites = angular.copy(this.user.sites);
          this.user.sites = _.map(this.user.sites, site => site._id);
        }
        if (this.mode === 'CREATE') {
          this.User.save(this.user).$promise
            .then(user => {
              this.stopSpin('spinner-1');
              console.log(user);
              this.$uibModalInstance.close(user);
            })
            .catch(err => {
              this.user.organization = tempOrganization;
              this.user.sites = angular.copy(tempUserSites);
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        } else {
          this.User.update(this.user).$promise
            .then(user => {
              this.stopSpin('spinner-1');
              console.log(user);
              this.$uibModalInstance.close(user);
            })
            .catch(err => {
              this.user.organization = tempOrganization;
              this.user.sites = angular.copy(tempUserSites);
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        }
      }
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMesssage => {
            for (let fieldName in errorMesssage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMesssage[fieldName];
              }
            }
          })
        }
      } else if (err.data && err.data.message) {
        this.serverError = err.data.message;  
        console.log('validation err: 1', err);
      } else {
        this.serverError = 'Internal Server Error';
        console.log('validation err: 2', err);
      }
    }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditUserModalCtrl', AddEditUserModalCtrl);
})();
